import {useOutletContext,useParams} from "web-core/hooks"
import {axios} from "web-core/functions"
import {useEffect, useMemo, useRef, useState} from "react";
import { LazyLoadImage,trackWindowScroll } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import "../styles/work.scss"
import documents from "../assets/documents";
const projects = documents.works
const Gallery = ({images,preImages})=>{
    console.log(images)
    return (
        <div className={'gallery'} >
            {images.map((image,i) =>
                <div key={image.id}>
                    <LazyLoadImage
                        key={image.id}
                        alt={image.x}
                        src={image.thumbnailLink.replace("=s220","")}
                        effect={'black-and-white'}
                        placeholderSrc={image.thumbnailLink}
                        wrapperClassName={'preload'}
                        visibleByDefault={true}
                    /><br />
                    <span className={'name'}>{image.name.replace('.jpg','').split('. ')[1]}</span> <br/>
                    {/*<span className={'name'}>{image.name}</span> <br/>*/}
                </div>
            )}
        </div>
    )
}
const TrackGallery = Gallery
const Work = ()=>{
    const [load,setBlack,setHeaderWhite, setLeftVisible] = useOutletContext()
    const params = useParams()
    const [files,setFiles] = useState([])
    const [loading,setLoading]=useState(false)
    const currentProject = useMemo(()=>{
        let id = params['id']
        if(id===''){
            id=files[0].id
        }
        let project= projects.find(v=>v.id===id)
        return project
    },[params])
    useEffect(()=>{
        setBlack(true)
        setHeaderWhite(true)
        setLeftVisible(true)
    },[])
    useEffect(()=>{
        if(currentProject){
            loadProjectFiles()
        }
    },[currentProject])
    const loadProjectFiles = ()=>{
        setLoading(true)
        let url = 'https://www.googleapis.com/drive/v3/files'
        let payload = {
            key:'AIzaSyBZ3YuOAADY_Qhxdzq5BuEyuiOsO7K7O54',
            q:encodeURI("'"+currentProject.id+"' in parents"),
            fields:encodeURI("files(id,webContentLink, thumbnailLink, name)"),
            orderBy:'name'
        }
        var payloadString = Object.entries(payload).map(e => e.join('=')).join('&');
        return axios.get(url+'?'+payloadString).then(res=>{
            setFiles(res.data.files)
            document.querySelector('.gallery').scrollTo(0,0)
            setLoading(false)
        })
    }
    return (
        <div className={'work'} style={{opacity:loading?0:1}}>
            <section key={currentProject.id}>
                <div className={'info'}>
                    <h2 dangerouslySetInnerHTML={{__html:currentProject.name.replace(/\n/g,'<br />')}} style={currentProject.small?{fontSize:'20px'}:null}></h2>
                    <p dangerouslySetInnerHTML={{__html:currentProject.description.replace(/\n/g,'<br />')}}>

                    </p>
                </div>
                <TrackGallery preImages={currentProject.children} images={files}></TrackGallery>
            </section>
        </div>
    )
}
export default Work
