export default {
    works:[
        {
            name:'불;다 2024',
            description:'주관적이며, 심미적인 색과 선은 \n' +
                '관념이 지배적인 공간 안에서 \n' +
                '자신과 마주한다.',
            id:'1ipqWG2Rx4k_HD1gKFz1D_PQA73bb48Lg',
            children:[
                '001. 불_다 2022,mixed media on canvas,128x128(cm).jpg',
                '002. 불_다 2022,mixed media on canvas,128x128(cm).jpg',
                '003. 불_다 2021,mixed media on canvas,60.6x60.6(cm).jpg',
                '01. 불_다 2022,mixed media on canvas,60.6x60.6(cm).jpg',
                '02. 불_다 2022,mixed media on canvas,60.6x60.6(cm).jpg',
                '03. 불_다 2022,mixed media on canvas,60.6x60.6(cm).jpg',
                '04. 불_다 2022,mixed media on canvas,116.8x80.3(cm).jpg',
                '05. 불_다 2022,mixed media on canvas,116.8x80.3(cm).jpg',
            ]
        },
        {
            name:'불;다 2023',
            description:'주관적이며, 심미적인 색과 선은 \n' +
                '관념이 지배적인 공간 안에서 \n' +
                '자신과 마주한다.',
            id:'1hN-xJPm4hWwEHlCibIecQvSJAQR-wAIk',
            children:[
                '001. 불_다 2022,mixed media on canvas,128x128(cm).jpg',
                '002. 불_다 2022,mixed media on canvas,128x128(cm).jpg',
                '003. 불_다 2021,mixed media on canvas,60.6x60.6(cm).jpg',
                '01. 불_다 2022,mixed media on canvas,60.6x60.6(cm).jpg',
                '02. 불_다 2022,mixed media on canvas,60.6x60.6(cm).jpg',
                '03. 불_다 2022,mixed media on canvas,60.6x60.6(cm).jpg',
                '04. 불_다 2022,mixed media on canvas,116.8x80.3(cm).jpg',
                '05. 불_다 2022,mixed media on canvas,116.8x80.3(cm).jpg',
            ]
        },
        {
            name:'불;다 2022',
            description:'주관적이며, 심미적인 색과 선은 \n' +
                '관념이 지배적인 공간 안에서 \n' +
                '자신과 마주한다.',
            id:'1q75TL_j_MmSq_YAoO9s2raVA-Mn5q4nH',
            children:[
                '001. 불_다 2022,mixed media on canvas,128x128(cm).jpg',
                '002. 불_다 2022,mixed media on canvas,128x128(cm).jpg',
                '003. 불_다 2021,mixed media on canvas,60.6x60.6(cm).jpg',
                '01. 불_다 2022,mixed media on canvas,60.6x60.6(cm).jpg',
                '02. 불_다 2022,mixed media on canvas,60.6x60.6(cm).jpg',
                '03. 불_다 2022,mixed media on canvas,60.6x60.6(cm).jpg',
                '04. 불_다 2022,mixed media on canvas,116.8x80.3(cm).jpg',
                '05. 불_다 2022,mixed media on canvas,116.8x80.3(cm).jpg',
            ]
        },
        {
            name:'불;다 2021',
            description:'주관적이며, 심미적인 색과 선은 \n' +
                '관념이 지배적인 공간 안에서 \n' +
                '자신과 마주한다.',
            id:'1zMY05iBeSqTp7Gz_MZubKc2tBVlmmEPz',
            children:[
                '001. 불_다 2022,mixed media on canvas,128x128(cm).jpg',
                '002. 불_다 2022,mixed media on canvas,128x128(cm).jpg',
                '003. 불_다 2021,mixed media on canvas,60.6x60.6(cm).jpg',
                '01. 불_다 2022,mixed media on canvas,60.6x60.6(cm).jpg',
                '02. 불_다 2022,mixed media on canvas,60.6x60.6(cm).jpg',
                '03. 불_다 2022,mixed media on canvas,60.6x60.6(cm).jpg',
                '04. 불_다 2022,mixed media on canvas,116.8x80.3(cm).jpg',
                '05. 불_다 2022,mixed media on canvas,116.8x80.3(cm).jpg',
            ]
        },
        {
            name:'心 ㅡ 풍경',
            description:'삶의 희노애락이 체화되고 쌓여져 \n' +
                '묻어난 정신의 결과물들로 드러나며,\n' +
                '나의 표현 행위들 안에서 \n' +
                '숨 고르기하며 \n' +
                '답을 하고 있다. ',
            id:'1mQD96Ai6xZLb0Oe7Be2QX1qq2XWtZwNh',
            children:[
                '01. 心_풍경 2018, Mixed media on canvas,lithography,80.3x116.8(cm) .jpg',
                '02. 心_풍경 2018, Mixed media on canvas,80.3x116.8(cm).jpg',
                '03. 心_풍경 2018, Mixed media on canvas,lithography,90.9x65.1(cm).jpg',
                '04. 心_풍경 2019, Mixed media on canvas,90.9x65.1(cm).jpg',
                '05. 心_풍경 2020, Mixed media,lithography on canvas,90.7x72.7(cm) .jpg',
            ]
        },
        {
            name:'삶 ㅡ 풍경...愛',
            description:'내 삶의 풍경 안에서 \n' +
                '머물고 흩어지고 사랑하며, \n' +
                '존재라는 것에 \n' +
                '나를 다시 돌아 본다.',
            id:'1JcllY4vcio5mzS72UOBhZystPho4krYe',
            children:[
                '01. 삶-풍경..愛 2015,acrylic,mixed media on canvas,lithograph,91x91(cm).jpg',
                '02. 삶-풍경..愛 2014,acrylic,mixed media on canvas-lithograph,73x73(cm).jpg',
                '03. 삶-풍경..愛 2015,acrylic,mixed media on canvas,lithograph,73x73(cm).jpg',
                '04. 삶-풍경..愛 2015,acrylic,mixed media on canvas-lithograph,91x117(cm).jpg'
            ]
        },
        {
            name:'삶 ㅡ  \n바라보고... 바라보기',
            description:'소리없는 조용한 \n' +
                '울림들 안에서 \n' +
                '세상의 숨소리를 \n' +
                '듣고 싶었다.',
            id:'1m3Dy2GPWEhTR4a-xdwIqo0t9jX7ea7pM',
            children:[
                '01. 삶-바라보고...바라보기 2010,mixed media on MDF-lithograph,75x47(cm).jpg',
                '02. 삶-바라보고...바라보기 2010,mixed media on MDF-lithograph,75x47(cm).jpg',
                '03. 삶-바라보고...바라보기 2010,mixed media on MDF-lithograph,50x50(cm).jpg',
                '04. 삶-바라보고...바라보기 2010,mixed media on MDF-lithograph,50x50(cm).jpg',
                '05. 삶-바라보고...바라보기 2010,mixed media on canvas-lithograph,50x50(cm)_.jpg',
            ]
        },
    ],
    critiques:[
        {
            title:'확장된 사유의 지평, 시간의 붓질에 녹인 존재의 근원',
            author:'홍경한, 미술평론가',
            sub:'2023 작가 김광미 선광미술관 개인전 서문',
            menu:'홍경한',
            content:'[관조와 사유]\n' +
                '작가 김광미의 근작은 상상력을 자극하고, 다양한 해석을 가능하게 한다. 순수한 조형 요소로만 구성되어 있음에도 깊이가 남다르다.(*1) 색이 선과 부딪히고 여백이 감정과 충돌한다. 배경은 작자와 타자 사이에 걸친 균형의 미를 유지하는 장치다. 부딪힘과 충돌은 이 배경에 의해 조정되고 조율된다. 그럼에도 충돌에 의한 파장은 보는 이들에게 서서히, 나지막이 옮겨진다. 관람자가 느끼는 건 바로 그 파장에 의한 여울이다.  \n' +
                '2018년 당시 기술했던 것처럼(*2) 근작들 역시 일종의 내재율(內在律)에 귀속된다. 하지만 그때와는 달리 현재의 작업들은 무념무상의 무규칙적이라 여겨지다가도 흡사 선(禪)이 도(圖)의 개념으로 전환된 것이 아닌가 싶을 만큼 사유의 진폭이 크다. 이는 비워져 있으나 채워짐의 여운에 의한다. 이른바 충만함으로서의 공(空)이다. \n' +
                '선광미술관 개인전 출품작이 과거의 작업과 다른 점은 한층 더 강해진 비 작위성에 있다. 그의 작품은 ‘삶-풍경…愛’에서 ‘불:다’ 시리즈로 변화하면서 굳이 무언가에 맞추려 하질 않고, 불규칙적 계획성과 즉흥적 행위가 결합된 표현의 자유로움이 훨씬 커졌다. 역설적이게도 마음에 부족함 없는 감정인 충연유득(充然有得)은 그곳에서 비롯된다.\n' +
                '또 하나의 구별점은 이전의 경우 일부나마 그린다는 행위가 유효했다면 현재는 그려내지 않는 그림이라는 것에 있다. 만약 어떤 감각적 표현조차 ‘그리다’라고 한다 해도(*3), 지각의 영역에 귀속되지는 않는다.(*4) 그의 조형은 관조하기, 바라보기, 서사화하기를 더한 사유하기의 연장이다. 기호적이고 상징적이다. \n' +
                '그의 작업에서 눈에 띄는 건 덧붙임 없는 색이다. 그런데 그냥 색이 아니다. 작가에게 색은 곧 빛인 듯싶다. 그 빛에 에너지가 실린다. 거칠고 황량한 화면은 빛에 의해 강렬해지고, 덕분에 무의식적으로 행한 행위로 인한 인간의 근원성은 더욱 도드라진다. \n' +
                '획(劃)으로 불러야할 선(線)은 작업의 중심이다. 그것은 신체의 움직임과 각도에 따라 흐름 및 방향, 굵기의 차이를 유발한다. 선은 조형의 축이지만 기본적으로 감정의 전달을 흡수하는 수용체에 가깝다. 즉, 그의 작업에서 선은 그 자체로 작가 심연 깊숙이 들어 있던 관조와 묵상으로서의 존재의 이유를 덜어내듯 채우고, 다시 비워내는 ‘장소’인 셈이다.\n' +
                '근작엔 구상적이랄 수 있는 형상이 없다. 작품과 작품을 이어주는 시공만이 부유한다. 약한 질량이 느껴지는 질감은 화면을 타고 흐르는 선에 상주한다. 이 중에서 형태라고 부를 수 있는 것은 여백(*5) 속에 안착된 선뿐이다.\n' +
                '내용적으로 김광미의 근작은 일차적으로 ‘자아(ago)’에 대한 고찰이다. ‘심리의 그늘’ 밑에서 피어난 감정, 의지와 같은 여러 심리적-정신작용의 주관임을 추정케 하면서도 인간의 고뇌와 번민, 말할 수 없는 삶의 모든 내재된 의미를 존재의 근원적인 것과 변하는 것 사이에서 드러내는 표출과도 같다. 그러나 넓게는 불안한 인간의 위치, 생존을 위해 몸부림치는 현대인의 불가피해 보이는 상황과도 맞물린다. 이처럼 확장된 해석과 더불어 ‘의욕하고, 의욕 하지 않으며, 상상하고, 감각할 수 있도록 하는 것’(*6)이 김광미 작업의 특징이다.\n' +
                '김광미의 작품은 (포괄적 관점에서 볼 때) ‘나’를 포함한 인간을 둘러싼 존재의식(*7)에 대한 규정이라고 할 수 있다. 미학적으론 이를 실존주의(existentialism)라 한다. 실존은 항상 특수하고 개별적이며, 존재양식에 대한 문제이다. 따라서 김광미의 작업은 나를 에워싼 근원적인 자문, 즉 존재 의미에 대한 탐구이기도 하다.(*8)\n' +
                '존재 의미에 대한 탐구는 끊임없이 다양한 가능성에 직면한다. 인간은 이 가능성들 가운데서 선택하고 그 선택에 몸을 맡긴다. 그렇지만 우린 대개 선택을 제한·제약하는 구체적 상황 속에 위치한다. 김광미는 이를 진정한 자기 상실(loss of self identity)로 여긴다. 그리곤 인간은 과연 무엇이고 어떤 의미인가라는 철학적 고민을 회화라는 조형을 통해 묻는다. 가시적이진 않으나 사실상 단순한 선(획)과 색 사이에 이 모든 것이 들어 있다.\n' +
                '\n' +
                '[전화(轉化)의 과정]\n' +
                '인간은 늘 무언가를 표출해왔다. 진화 단계에 자리했던 300만 년 전의 초식성 인류인 오스트랄로피테쿠스를 비롯해 호모 에렉투스, 호모 에르가스터 등은 말할 것도 없고, 호모 사피엔스 사피엔스도 그랬다. 아니 인류가 생겨난 이후 줄곧 표현은 있어 왔다. 이는 본능의 결과다. 예술가는 조금 더 민감하고 예민하며 표현에 전적으로 의탁한다는 차이가 있을 뿐, 인간은 기본적으로 뭔가를 만들고 그리는 동물인 것이다. \n' +
                '하지만 예술가로서 갖고 있는 창조 본능과 제작 능력을 이용해 하나의 결과물들을 쏟아내는 게 말처럼 쉽지는 않다. 독창성은 또 다른 개념이며 누구에게나 주어지진 않는다. 그리고 미적 태도를 유지한 채 작가만의 감성과 나름의 시각이 투영될 때 비로소 독창성을 유지할 수 있다. 여기엔 ‘환경’도 의미 있는 영향을 미친다. \n' +
                '작가 김광미의 부친은 1세대 사진작가로 평가받는 김수열이다. 1938년 출생하여 1983년에 작고했으니 그가 세상을 떠난 지 올해로 40년이 됐다. 한국사진사에 있어 의미 있는 지점을 관통하는 그의 대표작은 많지만 그 중에서도 ‘노인’ 시리즈는 인간 삶에 대한 통찰을 보여준다.  \n' +
                '작가 김수열이 남긴 흔적은 세 개가 있다. 첫 번째가 ‘별 사진관’이다. 현재는 문을 닫았지만 그는 생전 ‘별 사진관’을 운영했다. 별세 이후에도 90년대까지 지속됐다. 아직 부천시 송내동의 건물은 그대로다. 어쩌면 그 곳 어딘가에 김수열의 잔상이 새겨져 있을 수도 있다.\n' +
                '두 번째는 다양한 그룹 활동을 통한 지역 사진예술의 뿌리를 심었다는 점이다. 자료에 의하면 그는 1968년 ‘렌즈클럽’을 결성하여 경인지역최초의 사진그룹을 조직했으며 1972년에는 한국사진작가협회 소사지부(현 부천지부)를 창립하는 등 문화적 자긍심을 바탕으로 한 지역사진발전의 초석을 쌓았다.\n' +
                '세 번째는 무엇보다 소중한 유산이라고 할 수 있는 예술적 환경이다. 그것을 이어받은 이는 김광미다. 그러므로 김광미의 예술은 지극히 자연스럽게 이뤄져 왔다는 합리적 추론이 가능하다. 어려서부터 보고 듣고 접한 것이 예술, 예술가이고, 삶에 대한 이해이며 미적 방향이었을 것이기 때문이다. 결국 김광미는 예술계 선배이기도 한 부친이 물려준 예술적 환경이라는 테두리에서 자유롭지 못하다.\n' +
                '김수열은 시대성과 인본사상을 중시했다. 예술가의 역할 또한 같은 결로 봤다. 그러나 김광미는 약간 다른 길을 걷는다. 아버지의 예술정신을 이어 받아 실험적인 면모를 유지하고 인간에 대한 통찰 및 예술가의 숙명적 삶에 대해 인정한 채 동일한 예술가로서 살고 있지만 세상을 보기 위해 나를 투사하는 것과 나를 통해 세상을 보는 것엔 차이가 있다.(*9)\n' +
                '실제로 김광미는 자신을 향해 끝없이 질문한다. 내재된 정신의 원초적 본질(本質)에 보다 집중하며, 대상의 구별과 계산에 앞서 직관적인 감성에 의탁한다. 이는 ‘나’를 비롯한 우리의 근본을 열람하는 창이 된다. 많은 이들은 이질적 소외와 동시대를 살아가는 객체 누구라도 갖고 있는 의식 혹은 이념이 일종의 변증법적 운동을 거쳐 발전하는 과정을 통해 본래 자기가 의도한 것과는 다른 것이나 원래와는 반대의 것으로 전화(轉化)하는 과정을 열람하게 된다. \n' +
                '전화의 과정에는 이상적, 정신적인 것을 부정하거나(당하거나) 상실한 인간들, 자기를 배척하며 물질적으로 경도되는 현상까지 포함된다. 이는 인간 일상의 한 측면이기도 하지만 심원으로 접근하면 다분히 철학적인 것일 수도 있다. 그것은 삶의 희로애락이 체화되고 쌓인 정신의 결과물(*10)이다. 확장적으론 현시대에 있어 ‘진정한 인간상’은 무엇이고 근본적인 자아를 발견할 수 있는지에 대해 자문자답하는 것이라 해도 무리는 없다.\n' +
                '물론 그 대답은 현재진행형이다. 늘 의문부호로 남기에 지금도 작업을 잇는다. 다만 그것은 때로 부정적이며 욕구에 대한 불충분의 요소를 지니기도 한다. 그렇지만 김광미가 쏟아놓는 질문들이 무익한 건 아니다. 예술이란 본디 물음 위에 위치하는 탓이다.\n' +
                '\n' +
                '[실존적 자각과 회복의 미]\n' +
                '그는 오늘도 텅 빈 화면에 몸을 기댄 채 처절하게 말라 타들어가는 초라한 존재의 불안감을 읽고 ‘삶의 궤적’을 훑는다. 현재라는 시간 속에서 살아가는 인간들의 심적인 부분과 욕망을 김광미의 시선으로 이동시키고 있다. 그러면서 내재된 무형의 많은 것들을 조형언어로 밝히며 화폭마다 존재의 근원적인 것과 변화하는 것, 그 사이에서의 고뇌하는 인간의 현재에 관한 이야기를 심는다. 물론 그 중심에는 언제나처럼 작가 자신(*11)도 들어서 있다. \n' +
                '김광미의 근작은 살며 살아가며 느끼는 기쁨과 슬픔, 좌절과 고통, 비애와 환희 등을 미적 거름망으로 걸러낸 결과물이다. 동시대를 살아가는 인간이라면 누구나 공감 가능한 작업들이다. \n' +
                '작가는 “세월 속에 나를 가두지 않기 위해 끊임없이 스스로를 향해 질문을 던지며 생각과 마음을 관찰하는 것을 즐기는 것 같다.”고 했다. 그가 들숨날숨이 담긴 시간의 붓질을 통해 존재를 언급하며 심리적 자화상을 그리는 것도 같은 이유다. 내부에 숨겨져 있는 본연의 무엇을 끄집어내도록 하고 실존적 자각을 매개로 한 회복의 미를 추구하는 것, 삶의 무게를 짊어진 사유의 지평을 확인할 수 있는 것이 김광미의 작품이다.\n' +
                '한편 그의 그림은 가식적이지 않다. 진솔하고도 정직하다.(*12) 그래서인지 그의 근작을 접하며 필자가 느낀 것은 그저 아무런 생각을 갖지 않고 봐도 좋은 작품이라는 것이다.■\n' +
                '\n' +
                '\n' +
                '(*1) 근작은 과거 대비 단순화 되고 내밀해지고 있음을 보여준다. 이전보다 언어의 구사가 줄어들고 간결함이 도드라진다. 그것은 또한 매우 대담해졌고, 단정해졌다는 점에서 사유의 확장성도 강하다. \n' +
                '(*2) 필자는 2018년 그의 개인전 서문 《실존, ‘관조의 망루’에서 묻다-답하다 》에서 내재율(內在律)을 언급하며 “드러남과 감춰짐은 상보적 작용을 거치며 작품 내에 뿌릴 내린다. 마치 억 겹의 나날을 드러내는 것 마냥 서서히 말라 고착된 채 집약된 ‘삶의 궤적’에서 체감했을 법한 어떤 결을 깊이 있게, 함축적으로 보여준다.”고 썼다.\n' +
                '(*3) 그린다는 행위에 주안점을 두어도 그의 작업은 내 것을 찾기 위해 그리는 경향이 짙다. 남들이 그리는 그런 그림이 아닌, 자신만이 그릴 수 있는 색깔 있는 그림, 빛이 묻어 있는 그림을 그려가고 있다는 것이다.\n' +
                '(*4) 이는 한편으로 2018년 작업과의 연속성을 의미한다.\n' +
                '(*5) 다만 한국화의 여백이 추상적인 공간이라면 김광미의 공간은 실제적이며, 그의 작품 자체와 분리 할 수 없는 요소이다. \n' +
                '(*6) 데카르트(Rene Descartes)\n' +
                '(*7) 나로부터 시작된 존재의식은 타자성을 통한 깨달음으로써의 나를 지향하는 태도이다.\n' +
                '(*8) 물론 인간과 다른 사물 및 타인과의 관계에 의해 구성되기에 실존은 항상 세계내존재이다. 그래서 인간을 현존재라 부른다.\n' +
                '(*9) 이와 관련해 김광미는 다음과 같이 회고했다. “사진작가 아버지 김수열은 인간중심주의 작업에 몰두하며 다양한 각도의 실험성이 수반된 아방가르드한 사진 작업을 하셨다. 아버지의 예술정신은 어릴 적부터 습득의 감성이 아닌 본성의 예술로서 깊숙이 나에게 파고들어 선택이 아닌 필연적 예술가의 삶이 되어 오늘의 길을 걷고 있는 것이라 믿고 있다.”\n' +
                '(*10) 예술가에겐 사소한 그 어떤 것, 때론 낱낱의 모두(전체)가 창발의 배경이다. 삶의 경험도 마찬가지다. 그러나 소실점은 하나로 모아진다. 그리고 그 소실점은 ‘나’라는 정체성과 무관하지 않다. 이 정체성이 작품으로 전치되고 있음은 두말할 나위 없다. 작가의 모든 과정과 결과는 결국 ‘나’를 시점으로 모든 미의식과 표출을 담보한다는 것이다.\n' +
                '(*11) 작가 자신 내에는 다시 현실계에서의 결핍과 충족의 조형적 인용이 들어 있으며, 내적 혼란과 존재의 불안으로부터 벗어나려는 구원의 갈망, 자유로의 의미가 배어 있다. \n' +
                '(*12) 예전에도 기술했듯 그의 작업은 진실한 무대이자 세상에 대한 반응임을 고지한다. 세상이란 몸과 정신으로 느끼고 체감한 것들의 반영임을 일러준다. \n'
        },
        {
            title:'실존, ‘관조의 망루’에서 묻다-답하다',
            author:'홍경한, 미술평론가',
            sub:'2018 평론',
            content:'1. 작가 김광미 작품은 실존의 문제와 맞닿는다. 실존이란 항상 세계내존재이듯, 작가 역시 영적인 것과 육체적인 것과의 간극, 타자와 부딪히며 살아가는 인간으로서의 존재의식이 작업의 발로라고 할 수 있다. 그렇기에 삶과 풍경, 바라보고 바라보기, 숨과 애(愛), 심(心) 등을 키워드로 한 그의 그림은 단순한 추상을 넘어 특수하고 개별적이며, 존재 의미에 대한 탐구이면서 근본적으론 존재양식에 대한 문제를 다룬다. 그래서 김광미에게 예술이란 ‘현존재’에 관한 예민한 자기투영이다.  \n' +
                '김광미의 작품들은 삶에 있어 제한하거나 제약받는 상황에서 싹을 틔우며, 조형은 고의적 드러남과 감춰짐의 틈에서 자란다. 이는 일종의 내재율(內在律)로, 드러남과 감춰짐은 상보적 작용을 거치며 작품 내에 뿌릴 내린다. 마치 억 겹의 나날을 드러내는 것 마냥 서서히 말라 고착된 채 집약된 ‘삶의 궤적’에서 체감했을 법한 어떤 결을 깊이 있게, 함축적으로 보여준다.(*1)\n' +
                '‘삶의 궤적’은 두 가지 등선에 위치한다. 첫 번째는 조형의 빌미다. 작가가 오랜 시간 화두로 삼은 관조하기, 바라보고 바라보기는 곧 ‘서사화하기’라고 할 수 있다. 이것은 궤적 위에 놓이는 구체적 언어요, 표상화 되는 순간 생성되는 ‘궤적의 풍경’이라 해도 무리는 없다. 하지만 애(愛)와 심(心)이 암시하듯 아이러니하게도 이는 가시적 범주에 있지 않다. 시각적 발견은 불가능하며, 읽기도 어렵다. 무언가를 꾹꾹 눌러왔듯 기호적이고 상징적이며 에두르는 탓이다.(*2)  \n' +
                '다만 우린 그의 그림(표상체계)을 통해 감정이입을 하고, 물성과 반비례한 관념의 노획을 통해 찰나의 연속인 존재에 관한 자문을 헤아릴 수 있다. 역대 추상표현주의 그림에서 알 수 있듯 공명이란 반드시 재현의 영역에서만 이뤄지는 것은 아니기 때문이다. 하지만 김광미 특유의 내재율이 지닌 특성은 알 수 없는 마음의 동요를 전달하기에 부족함이 없다. \n' +
                '‘삶의 궤적’ 그 두 번째는 삶과 직접적으로 연관되는 자아의 투사이다. 김광미의 작품은 ‘심리의 그늘’ 밑에서 피어난 감정, 의지와 같은 여러 심리적-정신작용의 주관임을 암시한다. 그곳엔 환상이 없다. 자신을 옥죄는 세상이라는 그물도 없다. 대신 현실계에서의 결핍과 충족의 조형적 인용이 들어 있으며, 내적 혼란과 존재의 불안으로부터 벗어나려는 구원의 갈망, 자유로의 의미가 배어 있다. 그리고 이 모든 것은 자의식을 소실점으로 하며, 그 소실점을 축으로 굴레로부터의 자유, 소통, 욕망, 절제, 관조, 성찰 등의 명사가 놓인다. \n' +
                '마티에르 두터운-거칠고 투박한 옛 작품들도 그렇지만, 김광미의 근작 또한 어쩌면 불완전할 수밖에 없는 존재에 근접한다. 삶을 지탱해온 무언가의 한 귀퉁이에 의지한 채 본능적으로나마 느끼는 유토피아를 향해 놓여 있다가 처절하게 말라 타들어가는 초라한 존재의 불안감이 배회하는 것도 그런 이유다. \n' +
                '\n' +
                '2. 김광미의 그림은 화장기 없는 맨 얼굴로 마주할 수 있는 진실한 무대이자 세상에 대한 반응임을 고지한다. 작가에게 세상이란 몸과 정신으로 느끼고 체감한 것들의 반영임을 일러준다. 특히 그의 근작들은 살며 살아가며 느끼는 기쁨과 슬픔, 좌절과 고통, 비애와 환희 등을 미적 거름망을 통해 걸러낸 결과물이다.(*3) \n' +
                '흥미롭게도 김광미의 모든 작업은 구성되어질 수 있는 어떤 것을 빗대더라도 구체적 사물로부터 시작된 재현이 아니다. 그렇기에 그의 작업은 감각적, 직관적 흐름이 다분하고, 형태는 모방에 충실하지 않다. 하지만 그것이 오히려 관자들의 가치관 혹은 관점에 따라 여러 가지 격정적 시상(詩想)을 제공하며 김광미의 작품은 그 관점에 너그럽다. \n' +
                '일례로 흑과 백으로 분리된 채, 삼각형, 사각형의 기하학적 구성을 하고 있는 그의 여러 근작들은 표면적으로 앞서 기술한 삶의 풍경과 직접적이지 않다. 그보단 면과 선, 색과 사물이라는 조형요소를 통한 유보의 관념과 가감의 보류라는 개념이 앞선다. 여기서 가감은 곧 비움과 채움의 활성화(또는 정체화)를 나타내나 ‘비움’보다는 ‘채움’의 여백주의를 실천하는 방식으로 고유한 미감을 획득하고 있다는 게 중요하다. \n' +
                '다소 물질성이 두드러지는 일부 오브제 작업도 매한가지다. 금속판에 타원형의 묘한 이미지를 새긴 이 오브제(*4)는 어둡고 건조한 공간에서 독자성을 띤다. 재료가 금속이기에 평면의 회화와는 달리 물질성은 한층 강조된다. 그러나 그러함으로써 조형적 대비는 두드러지고, 개인적 함의에 집중되는 역할을 한다. 이 오브제는 여성인 작가 자신의 히스토리를 포괄하며, 평면과 입체로 조응시키면서 (의외의, 불현 듯, 예상 가능한)반응의 공간을 형성한다. \n' +
                '이 가운데 그의 그림에 자주 등장하는 산(山) 모양의 도형과 삼각형, 여타 작품에서 곧잘 표현되는 인체와 자연물 등은 주체에 관한 은유적 서술이다. 특히 근작에서 강조되곤 하는 화면 분할은 비가시적인 이미지를 통해 숨겨진 공간을 보다 확장하면서도 동시에 기존의 열린 공간을 구획 짓는다는 점에서 양가적 속성을 지닌다. \n' +
                '양가적 속성은 말 그대로 작가 개인과 공동체의 단락을 의미하는 것이자 속박의 오늘이고, 조응을 뒤로한 관조이자 예민한 관찰이다. 관조와 관찰의 조형인 넓은 여백은 궁극을 향해 줄달음치지만, 시공을 포박한 비물질이면서 물질인 오브제 및 이미지와 결합됨으로써 내면의식과 외부와의 관계를 소환한다. 물론 이를 확장하여 보면 단절과 소통, 주체와 타자 간 상호성에 관한 실마리로 자리하며, 이 역시 김광미 작업의 화두인 삶의 풍경에 있어서의 동질화와 탈영역화를 통한 현존에 관한 자문임을 확인할 수 있다. 그리고 이와 같은 흐름은 타자에게 밀도 있는 공감을 선사한다. 그의 그림을 통해 되레 자신을 반추한다. \n' +
                '이처럼 김광미의 작품들은 실존과 절대적 근원에 관한 고민의 무대다. 간결하게 다가오는 시적 형용의 단초이기도 하다. 특히 삶의 여정에서 거둬들인 가시적인 것과 감추어진 것, 의식과 무의식 사이에서 비동일성을 리듬 있게 연주하는 것과 갈음된다.(*5) 그러고 보면 김광미에게 예술이란 무정형의 정형이며, 시작은 사변적이나 결과는 미학적 차원에서 변형되고 새로운 의미를 형성하는 과정과 다름없다.(*6) \n' +
                '\n' +
                '3. 김광미의 작품들은 자신에게 주어진 삶의 무게를 균등하게 산포하는 유일한 도구이면서 존재성과 의미를 담아내는 거푸집이다. 내면과 마주하는 통로이자 거울이고, 자신만의 예술적 정신과 가슴에 끝없이 쌓이는 희로애락을 담은 장렬한 장소이다. 이를 미학적으로 풀어도 동일하다. 가령, 삶과 그 삶에 관한 시선, 정체성에 대한 질문은 작품에 드리운 공간성과 더불어 공소(空所)의 미(美)라는 원리, 시간성을 동반한다. 전체적으론 자아와 존재의식, 실존에 관한 의문, 자연의 일부로서의 삶에 관한 깊은 성찰이 배어 있지만 동시에 각각의 요소마다 고유의 시간성을 함유하고 있다는 점에서 동시적 파편성을 띤다. 이는 공간, 즉 분할된 화면에서 드러나는 실제의 공간과 기호의 공간으로 구분되고, 실제의 공간과 기호의 공간은 모두 김광미 자신으로 귀납되는 공통점이 있다.\n' +
                '귀납을 가장 효과적으로 나타내는 작업은 근래 작업에서 더욱 강해진다. 기본적으로 삶과 일상의 의미복제라는 프레임은 달라지지 않았으나, 시공의 변주와 맞물린 심리성이 보다 간결하게 함축되어 있다. 작품자체는 매우 체계적이나 즉흥적이고 임시적 공간을 통한 공감각적 상황은 무언가에 대한 집요함이 녹아 있다. 이는 표현한다는 행위와 감각의 가능성을 탐구하고, 묘사와 그리기를 넘어 인지와 사고, 개념에 치우치지만 오히려 그것을 통해 새로운 미적 가치를 일깨우기 위한 시도라는 점에서 눈여겨볼 만하다. \n' +
                '누구에게나 인식 가능할 만큼 이해가 원활한 것도 아니고, 취합의 구조 역시 이치를 따지기도 어려우나, 그는 물리적, 심리적 제약 혹은 원초적 내면을 작품에 그대로 수용하여 자연스럽게 삶의 일상성이 미술에 침투하게 둔다. 끊임없이 길을 잃고 헤매면서 길을 찾으려는 (일부 작품에서의 격렬한 선과 붓질에서 알 수 있듯) 뜨거운 몸짓, 불안한 인간의 위치, 생존을 위해 몸부림치는 현대인의 불가피해 보이는 상황을 고스란히 녹여내고 있다. 작가는 이와 같은 조형어법을 통해 삶의 일상성을 깨트리고, 시공을 숙고하며 심리적 해방을 맛본다.(*7)  \n' +
                '따라서 우린 그의 그림을 보며 삶과 존재라는 미지의 세계를 고찰하며, 삶과 이상이라는-현실적 이상과 이상적 현실이라는 양립불가능성에 관한 가능성의 이미지를 훑는다. 나아가 특정 카테고리 내부로 스스로 귀속시켜온 관념을 해체함과 더불어 새로운 질서마저 맛보게 된다. 그건 밝은 성품 뒤에 아련한 슬픔이 들어 있고, 다른 방도가 없기에 어떻게든 이어가려는 노력과 현존성을 탐구하는 삶의 모색을 그의 작품을 통해 체감할 수 있기 때문이다.\n' +
                '한편 오늘의 작업을 잇는 수년 전의 작품들(*8)은 현재의 작업에 비해 상당히 투박하다. 인간, 나뭇잎과 같은 자연물, 기호로써의 도형과 오브제의 활용이 보다 구체적으로 적시되어 있어 현실을 터전으로 한 억눌림과 비틀림, 작가로서-인간으로써 살아야 할 동시대 희망과 애환이 진하게 서려 있다. \n' +
                '그에 비해 근작들은 군더더기를 제거한 여운이 크다. 살아가야 한다는 것, 붙잡아야할 삶의 지속이란 실제적 삶에서 어떤 가능성들의 분절과 집합을 의미하고, 그것은 본질적으로 작가적 삶의 고지에 해당된다. 또한 타인과의 관계에 의해 직조되는 실존의 세계와 맞닿고 있다. 그러기 위해 설명은 줄였고 함축적인 의미만 골라냈다. 때문에 현존의 자각을 선험한 채 보다 짙은 체험을 유발하는 매개로써의 그림이라는 인상이 강하다. \n' +
                '특히 작금의 작품들은 흡사 나무가 나이테를 두르듯 명도의 짙음이 가중되고 있으며, 이는 순연의 삶, 운명일 수 있는 현실에 관한 작가적 진솔함을 더욱 빛나게 한다. 그렇게 그는 ‘관조의 망루’에 선 채 매일같이 삶과 실존에 대해 물으며 답하고 있다.\n' +
                '\n' +
                '\n' +
                '(*1) 여기서 말하는 어떤 결이란, 삶과 죽음 사이에서 체감한 절망이나 현실의 암담함 혹은 그것으로부터 비롯된 깊은 어둠일 수 있다. 어쩌면 영적인 것과 육체적인 것과의 간극, 세상과 부딪히며 살아가는 우리 인간이 느낄법한 모든 순간자체일 수도 있다. 그러나 그건 어디까지나 문자화 혹은 조형화되지 못하는 것이기도 하다. \n' +
                '(*2) 그러므로 눈에 보이는 이미지는 어디까지나 하나의 장막일 뿐이다.\n' +
                '(*3) 걸러낸 자리, 즉 내면과 외계 간 마찰과 충돌로 거친 듯 조용하게 여울진 장소에 들어선 건 내면을 향한 희구(希求)이다. 원하는 것, 바라는 것, 보이는 것, 볼 수 있는 것을 포함한 모든 풍경이다.\n' +
                '(*4) 작가는 이 오브제에 대해 다음과 같이 말한다. “사회적동물일 수밖에 없는 인간의 존재감과 원초적인 나의 존재감 사이에서 부딪히는 이성과 감성 사이의 내밀한 생각들을 표출하기에 지금의 이 오브제들은 현재 진행형으로 내겐 중요한 부분 중 하나로 선택 되어지는 것이다.” 작가는 그동안 흙이나 짚, 인쇄용 마판(석판화용 재료)을 문명의 부산물의 상징으로 사용해 왔다.\n' +
                '(*5) 작가는 자신의 내부에 존재하는 실존의 가치에 의미를 두어 왔고, 굳이 숨겨져 있을 이유는 없을 내레이션, 그림을 통해 자신의 내부를 들여다보고, 그동안 스스로를 지탱시켜온 에너지, 자아와 연관된 표출의식임을 지향하려는 작가의식을 예술로 담아 왔다.\n' +
                '(*6) 그 과정의 단락을 들여다보면 이성의 묘사가 아닌 순간적이고 날카롭고 뾰족한 직감-그러면서 한없이 포용적인 프로세스를 지니고 있음을 볼 수 있다.\n' +
                '(*7) 재밌게도 심리적 해방은 하나의 공간 속에서 병렬-중첩되면 기존 시청각적 체계는 무너지고 새로운 영역이 만들어지며 구축된다. 여기엔 구분, 제지, 차단이라는 규칙적 용도의 의미까지 포함된다.\n' +
                '(*8) <삶-풍경…애(愛)>연작이나 <삶-바라보고-바라보기> 시리즈 등이 그렇다.  \n' +
                '\n'
        },
        {
            title:'Existence and Conversation at\n' +
                '‘Pharos of Betrachten’',
            author:'Kyunghan Hong, Art Critic',
            sub:'2018 Critique',
            content:'1. The works of the artist, Gwangmi Kim, encounter a question of existence. As existence is about consciousness of being in the world, an artist’s manifestation begins with realizing the gap between ‘spiritual and physical’ matters and self-awareness of existence as a human being living with others. Thus, her paintings, with themes such as life and landscape, contemplation, mind, breath and love, aim for specified and personal matter beyond simple abstract form, and deal with the signification of being and the way one exists. To her, art is a sensitive act of self-reflection about being. \n' +
                '\n' +
                'Gwangmi Kim’s work sprouts under circumstances of restricting or being restricted in life, and the art form she seeks to create grows between revealing and concealing. This is a kind of inner rhythm. Revealing and concealing spread their roots in her work based on their complementary relation. The grain of her art appears deep and implicit in the work so that a trajectory of her life, which exposes the gradual and anguished time of dried up stagnation to be finally integrated in the days of eon, is reflected.(*1)\n' +
                '\n' +
                'This ‘Trajectory of Life’ is placed on two different lines. The first line is about the cause of her art form. The subject upon which the artist has been elaborating for a long time is to contemplate, and such contemplation develops narratives. Then, the narratives become a specific language laid on the trajectory, enough so to be called a ‘landscape of trajectory,’ created at the moment that the language appeared. However, as love and mind imply, these ironically are not in a tangible range because they are semiotic, symbolic and indirect.(*2) \n' +
                '\n' +
                'Only through the way of seeing her painting, which indicates a system of representation in order to have empathy, and through the captured notion that is inversely proportional to materiality, can we fathom the self-questioning of being which lives in an endless repetition of moments. As shown in the history of Abstract Expressionism paintings, resonance does not only stand for the realm of representational art. In that, Gwamgmi Kim’s work embraces a unique inner rhythm, and is competent enough to sway people’s mind.\n' +
                '\n' +
                'The second line of the ‘Trajectory of Life’ is a self-projection that relates directly to her life. Kim’s art implies that her work relates mainly to the psychological reaction that conducts emotions and the will aroused in ‘the shade of mentality.’ There’s no fantasy and no toil named ‘society’ that oppress her. Instead, her work has formative citation that speaks for lack and fulfillment in the real world, and embodies the thirst for freedom and salvation from inner turmoil and the insecurity of being. The vanishing point of all those things is from her self-consciousness, and based on that vanishing point, lay nouns such as freedom from restraints, communication, desire, self-restriction, contemplation and introspection.  \n' +
                '\n' +
                'Kim’s recent work might be close to being, which can only be imperfect, much like her old works shaped with thick and rough materiality were. This imperfection is also the reason that humble being and the anxiety stirred from the desperate but wearisome waiting after the long yearning for the never-seen but instinctively sensed utopia, wander around.   \n' +
                '\n' +
                '\n' +
                '2. Gwangmi Kim’s painting states that her canvas is a truthful stage where she encounters the world with bare face, and where her reaction from encounter with the world spreads out. To her, the world is comprised of reflections generated from experiences through her body and mind. Her recent work especially is a result of joy and sorrow, frustration and agony, woe and triumph, and etc. filtered through her aesthetic skimmer.(*3)\n' +
                '\n' +
                'Interestingly every single possible element in Kim’s artwork is never a figurative representation originated from a certain object, and never comparable with anything. As such, her artwork tends to follow a sensible and intuitional flow, and never sticks to imitational representation. However, such a tendency allows viewers to have various poetic sentiments of their own based on different values and perspectives. In that, her art is generous. \n' +
                '\n' +
                'For instance, considering visual presentation in her painting, many of her recent works that are divided into black and white, and formed with geometric triangles and quadrangles, do not seem to relate directly to the concept of the ‘landscape of trajectory’ mentioned earlier. They focus more on concepts of reservation and the restraint of adjustment through formative elements such as sides, lines and colors. At this point, the adjustment means activating (or congesting) the act of emptying and filling, but in doing so, what really matters here is that she attains her unique sense of aesthetic from practicing the ‘principle of blankness,’ which in this case, focuses on ‘filling’ rather than ‘emptying’   \n' +
                '\n' +
                'Kim’s object art(*4) that has more distinctive materiality shares the same notion. The object that has eccentric oval images inscribed on a metal plate is charged with its own identity in a dark and dry space. As the object is made of metal, its materiality stands out even stronger, unlike a flat surfaced painting. In this regard, the formative contrast becomes more remarkable, and the artist’s undertone, which is personal and individual, gains more attention. This object embraces the artist’s personal history as a female artist, and the combination of flat and solid forms creates a space of reaction, which is predictable, unpredictable, and even somehow sudden.\n' +
                '\n' +
                'Among all those elements, the mountain-like figures, triangles, and human or nature figures that often appear also in her other works, is a metaphoric narrative of the subject. Particularly her split-screen technique emphasized in her recent work embodies ambivalence property in that the split line tends to expand the hidden space, which is implicit in non-visualized images through the line on the screen, and at the same time, closes the open space existing on the screen.   \n' +
                '\n' +
                'In her work, ambivalence property carries the means of segmentation between the artist as an individual and the community she belongs to. It is the restrained today, the distant contemplation from mutual accordance and sensible observation. The contemplation and the observation embodied in the wide blank dash for the ultimate, yet they summon the relation between inner and external conscious by merging the images and objects that embrace materiality and non-materiality in that they touch time and space. In a broad sense, such concepts become a clue to trace the reciprocity between severance and communication, and the subject and others. From this, we can understand that her work is to self-question in terms of existence realized through homogenization and de-territorialization of her life trajectory, which is the main topic of her work. Such concepts also offer deep empathy to others, who can have a moment to retrace themselves through her painting.\n' +
                '\n' +
                'As such, Kim’s work discusses existence and absolute cause. It also becomes a beginning of simply aroused poetic description, which contains visible and invisible things reaped from the journey of life, and playing non-identity with rhythms between the conscious and unconscious.(*5) To her, art is a formative form of non-formatives and the process that modifies and forms new value at the level of aesthetic dimension, which begins with speculative level.(*6)\n' +
                '\n' +
                '3. To Gwangmi Kim, her artwork is the only tool to scatter the weight of her life she carries, and the mole that contains her existence and meaning of life. It is a corridor to face her inner-self, and the place that encompasses her spirit of art and all the different emotions, which will be eternally accumulated. Her work will be explained the same from the aesthetic point of view. For example, questions about identity, life and the eyes for life are accompanied with spatiality, the beauty of the void, and the temporality permeated in her artwork. It carries self-conscious and self-awareness, questions about existence, contemplation toward life as a part of nature on the whole, yet at the same time, Kim’s artwork is charged with simultaneous fragmentation in that each element embraces its own temporality. In her painting, there are two split spaces, one for the actual space revealed on the canvas and another for the symbolic space. In here, the actual space and the symbolic space have one thing in common in that they all inductively return to the artist herself. \n' +
                '\n' +
                'In her recent art practices, Kim seems to emphasis such inductive process in her artwork. The basic frame of her artwork, which is a sematic reproduction of life and routine, does not change, but the psychological context engaged with the variation of time and space becomes simpler. The artwork itself is very systematic, but a strong degree of tenacity toward something is melted in with synesthetic circumstance created through spontaneous and occasional space. This is worth paying more attention to in that it becomes an attempt to study expressional behavior and sensible possibility, and to realize new aesthetic value developed from the act of perceiving, thinking and conceptualizing, which is beyond describing and drawing.   \n' +
                '\n' +
                'It is not easy for everyone to understand, and difficult to give reason to the gathering structure, yet she lets the dailiness of life naturally permeate her art by soundly accepting physical and psychological restriction and inner instinct to her work. Eager gestures to seek the way while ceaselessly getting lost (vigorous brush strokes and lines shown in some of her paintings reveal such gesture), the insecure position of human beings and the desperate gesture of contemporary human beings for survival are all melted down in the notion above. Through such artistic language, the artist contemplates time and space, and experiences psychological freedom.(*7)\n' +
                '\n' +
                '\n' +
                'Therefore, viewers contemplate the unknown world of life and existence by encountering her painting, and glaze over the possible image in terms of the incompatibility between the ‘realistic ideal’ and ‘idealistic reality.’ Furthermore, viewers can witness the destruction of self-restraint in a certain category, and eventually taste a new order. \n' +
                '\n' +
                'Meanwhile, her old works(*8) that connect a bridge to her today look quite rough compared to her recent works. Human figures, natural figures like leaves, symbolic figures and the use of objects appear more obvious to read distorted and oppressed reality derived from life, and frustration and hope as an artist and an human being in this contemporary society.\n' +
                '\n' +
                'By comparison, her recent work lingers large in the mind after taking out all superfluity. To live and to maintain life means segmenting and gathering in reality, which is fundamentally the ultimate goal of the artistic life. Her recent work also meets the world of existence that is weaved by relationship with others. To do so, explanation is reduced and connotative meaning stands out. Due to this, her painting becomes an agent that has gained the experience of awareness of existence, and causes even deeper experience for the others.\n' +
                '\n' +
                'Specifically, her art works in most recent days are tinged more vividly as if a tree grows its rings, and this illuminates her artistic honesty in terms of destined reality and veritable life. Like above, Kim stands at the ‘Pharos of Betrachten’ (the tower of contemplation) and self-questions and answers those questions about life and everyday existence.\n' +
                '\n' +
                '\n' +
                '\n' +
                '(*1) In here, ‘the grain’ might be despair experienced between life and death, from hopeless reality or the bottomless dark derived from all of those. Perhaps, it is the gap between spiritual and physical things, or every moment itself that we, human being, encounter in life. However, it is just never able to be literal or formative.\n' +
                '(*2) Thus, the image captured in our eyes is a mere shroud of one kind.\n' +
                '(*3) Filtered place. In other words, stepping in the calm and shallow place where inner side and external side rubbed and collided is to long for inner wish. It’s the landscape that embraces all the things you want, wish, look at and enable to see.\n' +
                '(*4) The artist says about the object as such: “For me, this object is a present progressive form where I express my inner though between reason and emotion, which collides with the being as myself and being as a human who is inevitably social animal. In that, it becomes important part for me to choose in my art for now.” The artist has been materials such as using dirt, straw and used polished plate for print as symbols of by-products of civilization.\n' +
                '(*5) The artist gives more weight on the value of existence in her, tries to confront her inner side through the narratives, which doesn’t have to be hidden, and the painting. She’s been aiming to be an artist who holds the energy that drives artistic expression related to her ‘self-conscious’.\n' +
                '(*6) Considering the each segmenting process, it’s not just a description of rational mind, but embraces momentarily sharp and pointed yet boundlessly accepting process.  \n' +
                '(*7) Interestingly when psychological relief is laid on juxtaposition and overlapped, existing audio-visual system collapses and new ground is built. In doing so, even the means of dividing, restraining and blocking for the regulative purpose are included.\n' +
                '(*8)  <Life-Landscape….Love> series or <Life-Staring-Contemplating>series does so.\n' +
                '\n' +
                '\n'
        },
        {
            title:'흩어지는 먹의 파편... 감칠맛 나는 그림',
            author:'이재언, 미술평론가, 인천아트플랫폼관장',
            sub:'2019 문화일보',
            menu:'이재언',
            content:'우리에게 그림과 글씨는 한 뿌리라더니 김광미의 그림이 그 전형을 보여준다.\n' +
                '우리 미의식의 근원으로 귀의하고 조회함으로써  차별화된 서법적 화면이 그것이다. 글씨 자체를 심미적으로 구현한 양식과 유산들이 풍부한 환경, 특히 글씨에서 다채로운 조형적 모티브를 추출해내는 DNA를 가진 우리에게 그의 화면이 공감시켜 주는 것이 많다.\n' +
                '\n' +
                '보통 종이위에 모필로 이뤄지는 글씨는 획과 결구를 중시하기에 먹의 농담이 큰 의미를 갖지 못한다. 정갈함을 추구하는 서예에서는 군더더기로 간주되는 자잘한 것들이 있다. 오히려 그림 같으면  감칠맛을 더해주는 요소로 선호될 수 있다. 화가의 눈썰미는  글씨 그 자체보다는 서법에서 발생되는 그러한 부수 요소들을 증폭시키기 위해 종이가 아닌 다른 질료로 눈길을 돌린다.\n' +
                '\n' +
                '작가의 성취는 여운과 은유성을 자아내는 먹의 농담도 그렇지만, 갈필에서 파편처럼 흩어지는 입자들을 심미적으로 탐닉한데 있다.\n' +
                '화면이 순간의 일필휘지로 보이지만, 거기엔 먹(안료)의 농담과 파편 같은 점들이 치밀하게 계산돼 있다.\n' +
                '가늘지도 않으며, 지나치게 굵지도 않은 획의 비례, 글씨와 그림의 절묘한 접점인데 이 또한 계산된 것이 아닐까. 아니 본능일지도 모른다.\n' +
                '\n'
        },
        {
            title:'사랑의 눈으로 우주를 바라보고 나를 응시하라',
            author:'이근욱, 미술평론가',
            sub:'2015 아트플랫폼문화비평지',
            menu:'이근욱',
            content:'갤러리로 가는 차 안에서 라디오를 들었다. 진행자가 로렌차 젠틸레(Lorenza Gentile)가 쓴 『바람이 되고 싶었던 아이』라는 책의 일부를 낭송했다. 나는 꼭 이겨야 하는 아주 중요한 전투를 하고 있는데, 나폴레옹만이 나를 도와 줄 수 있다. 여덟 살 테오는 나폴레옹을 만나려면 자신도 죽어야 한다고 생각한다. 그러다 사람이 죽어서 어떻게 되는지 궁금해졌다. 하지만 만나는 사람들은 테오의 질문에 관심이 없다. 수지 아줌마만 빼고. \n' +
                '테오는 수지 아줌마에게 진지하게 질문한다. 아줌마, 사람이 죽으면 어떻게 돼요? 아줌마가 대답한다. 다른 무언가로 다시 태어나지. 그래서 사실 죽음은 존재하지 않아. 테오의 질문이 계속 이어진다. 그러자 수지 아줌마가 테오에게 말한다. 일단 자고, 꿈속에서 답을 찾아 봐. 꿈은 현실보다 더 진짜일 수 있어. 왜냐하면 네 안에 있는 거니까. 네 거니까. 문득, 화가 김광미의 작업이 테오가 가진 삶의 의문과 다르지 않다는 생각이 들었다. 우주와 인간, 삶과 죽음에 관한 이야기였다. 무엇을 내려놓으려는가? 분명한 것은 내려놓아야 다시 얻을 수 있다. 가을이 성큼 다가온 날, 푸른빛 나뭇잎이 떨어지는 광경은 손아귀에 힘을 주어 움켜잡고 있는 욕망의 근육을 느슨하게 했다. \n' +
                '  \n' +
                '인천광역시 중구 신포로 15번길 69에 위치한 갤러리 지오(Gallery GO)는 3층으로 나눠진 공간을 가지고 있다. 1층은 카페이고, 2층과 3층은 여느 갤러리처럼 흰 벽으로 된 공간이다. 세 공간은 좁은 계단으로 이어져 있다. 김광미는 사람들이 붐비는 1층을 1호에서 5호쯤 되는 캔버스로 채운 뒤 2~3층으로 올라갈수록 캔버스 크기를 점점 키워 간다. 서 있을 때와 앉아 있을 때의 주목도를 고려한 배치일까. 전체 40여 점 가운데 20여 점이 빼곡하게 걸려 있는 1층은 사람들이 자주 드나드는 것만큼 자유롭고 동적이었다. 하지만 2층과 3층의 분위기는 정반대의 상황을 연출한다. 의자에 앉아 한 작품을 집중할 수 있도록 작품을 배열하고 있다. 정적이고 고요한 분위기는 작품이 가진 메시지를 읽고, 사색하기에 충분했다. \n' +
                '  \n' +
                '김광미는 삶의 풍경을 그리는 작가다. 인간의 정신과 사유가 만들어내는 풍경을 화폭 위에 드러낸다. 삶의 풍경은 자연과 조우한다. 화폭은 변화무쌍한 자연의 일부를 그대로 불러들이고, 그 위나 아래, 혹은 좌우에 삶의 한 장면을 배치한다. 나뭇가지, 흙이 입체적으로 조화를 이루는가 하면, 테라코타 군상과 석판 조각과 같은 오브제와도 어우러진다. 평면과 입체, 자연의 소재와 인간이 만든 문화적 오브제가 상호 관계를 맺으며 삶의 다양한 풍경을 만들어낸다. 우리가 사는 오늘의 풍경 같은 것이다. \n' +
                '\n' +
                '2015년 S(Square)형 캔버스 50호(91×91cm) 작품 <삶-풍경…愛>는 검정 배경에 작품의 3분의 2를 나뭇잎이 여러 장 겹쳐진 봉우리가 차지하고 있다. 봉우리의 아래쪽에 알루미늄 판을 사람 형상으로 오려내 붙였고, 좌측에 산 모양의 형상도 붙였다. 마치 어두움 밤 커다란 나무와 멀리 있는 산만이 불빛을 받은 모양이다. 침엽수를 연상시키는 날카로운 잎들이 만들어내는 덩어리는 마치 산을 가까이에서 바라보는 느낌을 자아낸다. 디테일을 강조한 덩어리는 멀리 있는 산도 이와 같다는 암시를 내포하고 있다. 그리고 보일 듯 말 듯한 크기의 사람. 멀리 있는 산을 응시한다. 검은 우주 속 작은 자연, 작은 인간. 우주에서 바라봤을 때는 인간은 숲 속 작은 짐승과 다르지 않다. 작가는 자신의 작업에 대해 말한다. \n' +
                '  \n' +
                '‘자연의 이미지들을 통해 나의 삶과 정신을 담아내는 작업들…. 우연과 필연적 만남들 속 삶이 무의식과 의식을 통해 파헤치고 거르기를 거듭하며 본성적으로 다가설 수 있도록 오늘의 길을 드러내며 표현하고자 한다.’\n' +
                '  \n' +
                '밤은 모든 살아 있는 생명에게 쉼의 시간이다. ‘해는 서산에 지고, 저녁 발 빛난다. 새들은 보금자리 찾아야 한다. 천상의 높은 정자 위에 달은 한 송이 꽃과 같이, 조용한 희열 머금은 채 밤 위로 자리해 미소 짓는다.’ 윌리엄 블레이크(William Blake)의 시 <밤>에서 노래하듯, 인간의 보금자리는 어디인가. 인간은 결국 어둠이 내리면 어디로 돌아가야 하는가. 김광미의 그림에는 인간을 보살피는 자연, 더 좁혀 산, 더 좁혀 나무가 있다. 구체적으로는 인간의 정신, 사유를 온화하게 감싼다고 해야 할 것이다. \n' +
                '  \n' +
                '우주의 시간으로 인간을 바라봐야 한다. 우리가 이 세상에 머물 수 있는 시간은 고작 백 년. 150억 년 우주의 나이에 비하면 티끌만도 못하다. 유한한 삶의 끝을 잡고 인간은 무한한 생을 노래한다. 김광미의 그림 속에서 인간은 나무 사이 혹은 나무와 떨어져 있다. 인간은 나무들 사이나 나무 곁에 홀로 서 있다. 혹은 테라코타로 인간 형상이 군집을 이루어 일렬로 서 있다. 인간이 화폭 어느 위치에 있든 어떤 모습을 하고 있든 어색하기는 마찬가지이다. 작가의 내면에서 인간과 자연은 이미 하나가 아니다. 오늘날 인간을 보라. 이미 인간은 다시는 자연 속으로 돌아갈 수 없을 만큼 멀리 떨어져 있다. 이 지점에서 인간은 고뇌한다. 오랜 시간 자연 밖으로 나오길 공들였는데, 밖으로 나와서 나를 보니 내가 누구인지, 무엇인지 알지 못하겠다. 작가는 인간 실존의 시작을 ‘바라보기’에서 찾는다. 작가는 말한다. \n' +
                '  \n' +
                '‘인간 존재 가치에 대한 사유는 나의 눈과 마음으로 담아 놓았던 세계를 의문과 질문으로써 미학적 개념을 넘어 자아 성찰을 하게끔 한다. 나의 ’삶-바라보고...바라보기‘ 속 실존적 자아의 표징으로써의 석판군상과 융기된 이미지와 산의 형상은 현실적 내면의 정체성에 대한 소통 분출구이며 휴머니즘의 상징이라 할 수 있다. 우리의 삶이 결코 가볍지 아니함을 보여주는 시간 속 텍스추어. 이 소리 없는 조용한 울림들 안에서 세상의 숨소리를 듣고 싶었다. 우리의 정신적 나눔과 소통, 유대감을 서로를 바라볼 수 있는 의미 있는 진정한 삶이 아닌가 생각해 본다. 이러한 정신세계를 나의 예술표현 행위에 적용시킨 실존과 휴머니즘은 작품 안에 늘 공존해 있다.’\n' +
                '어느 날, 문득 한 알의 씨앗처럼 사람이 이곳에 왔다. 씨앗은 땅에 뿌리를 내리고 햇볕을 받고, 비와 바람을 맞으며 자신의 자리를 찾아간다. 사람도 마찬가지다. 누군가의 아이로 태어나 부모의 사랑을 받으며 자란다. 성장하면서 서서히 사회에 눈을 뜨고 가족의 울타리를 한 발 한 발 벗어나는 훈련을 통해 자신의 위치를 잡아간다. 육체적 성숙과 함께 정신적 나눔, 소통, 유대감을 공유하며 하나의 공동체를 이룬 인간 사회. 인간 사회는 구성원이 죽어 없어지고, 새로운 구성원이 들어오는 것에서 생동감을 찾던 단계를 넘어 인간의 삶과 무관하게 그 스스로 유기적 활동을 해나가는 지경에까지 이르렀다. 생동감 넘치는 인간 사회의 모습을 그리워하는 작가 김광미의 그림은 이 지점에 놓인다. \n' +
                '  \n' +
                '서양화가 김영웅은 김광미의 작품에 대해 이렇게 말한다. ‘그녀의 실험적 표현은 삶에 대한 회의와 부정을 딛고 자연의 순리와 긍정의 중간지대를 거치며 보다 명쾌한 형상들과 리듬을 획득함으로써 자아존재의 뚜렷한 발기와 적극적인 자아실현의 궤도를 형성해 내고 있다. 이는 몽환적 질감의 배경에 달과 구름 등의 구체화된 주변요소와 어울리며 내보여지고 드러내지는 자아의 실체를 힘 있게 융기된 산으로 상징 암시함과 동시에 강렬하고 명료하게 표현한 자아를 중심으로 자연적인 삶의 생동감을 유희하는 것으로 나타난다.’\n' +
                '  \n' +
                '작가 김광미는 이러한 말에 좀 더 구체적으로 자신을 표현한다. ‘작업들은 자연스럽게 나 자신의 정체성에 대한 고민들을 인간의 본질과 자연의 이치에 대한 탐구와 탐색으로서 풀어내 조형성을 넘어 필연적인 감성과 이성을 실험적 표현들로 다양하게 표출하고자 했었다.’\n' +
                '  \n' +
                '작가는 인간을 탐구한다. 그 탐구의 시작은 작가 자신일지 모르지만 결국 그것은 우리들의 이야기가 된다. 우주의 시간 안에서 유한한 생을 살아내야 하는 인간에게 지금 필요한 것은 자신을 바로 바라보는 것이며, 그 시선은 사랑의 시선이어야 한다. 사랑의 시선으로 나를 보고 세상을 보고, 자연을 보고 우주를 바라봐야 한다. 작가는 작품을 통해 세상에 이와 같은 메시지를 보낸다. 자연과 가까워지는 것, 자연 속으로 걸어가는 것, 자연을 닮으려는 것. 그 속에 사랑이 있어야 하고 그 사랑으로 인간의 삶은 밤하늘의 별처럼 영원히 잊히지 않을 것이다. \n' +
                '\n' +
                '\n' +
                '\n' +
                '#저자 약력\n' +
                '李根旭, 1972년 포항 생. 성공회대학교 문화대학원 겸임교수. 중앙대학교 공연영상창작학부 출강. 문화콘텐츠와 스토리텔링 프로젝트 그룹 다랑어스토리 기획이사로 활동하며 전시, 출판, 잡지 기획과 다양한 영역에 이야기 입히기 작업을 진행하고 있다. 2013순천만국제정원박람회 스토리텔링 개발, 『이어령 생명 동행전』(영인문학관) 등 전시 기획, 『이어령의 80초 생각나누기』 3권 시리즈 출판 기획, 월간 『노블리제』 편집장, 월간 『다른내일』 기획 및 편집 등 다양한 영역에서 문화기획 일을 하고 있다. masilbogi@empal.com \n' +
                '\n'
        },
        {
            title:'자기반성적인 경향성이 열어 놓은 존재론적 풍경',
            author:'고충환, 미술평론가',
            sub:'2015 평론',
            menu:'고충환',
            content:'삶, 바라보고 바라보기. 삶, 풍경. 작가 김광미가 자신이 그린 일련의 그림들에 부친 주제이며 제목이기도 한 이 개념은 작가의 그림을 이해하는데 일정한 길잡이 역할을 해줄 것 같다. 작가의 그림을 뒷받침하는 인문학적 배경 정도로 보면 되겠다. 먼저, 바라본다는 것은 일회적 사건이며 태도일 수 있고, 이런 바라보는 행위가 쌓여 일반화되고 관용적이게 될 때 바라보는 행위는 바라보기가 된다. 바라보는 행위가 주체의 적극적인 개입(이를테면 의식적인 관찰)에 방점이 찍힌다면, 바라보기는 이런 주체의 행위가 관조적이게 된 경우일 수 있다. 그러므로 삶, 바라보고 바라보기란 주제 혹은 제목은 다르게는 관조적인 삶 정도로 정리될 수 있겠다. \n' +
                '그리고 이보다 더 중요하고 결정적인 경우로서, 이 주제 혹은 제목은 실존주의적 자의식과 관련된다. 여기서 바라보는 행위는 주체로서 바라보는 시선과 객체로서 바라보는 응시로 분리되면서 통합된다. 이처럼 분리되면서 통합된다는 것은 주체로서 바라보는 행위와 주체로부터 객체로 자리바꿈된 주체가 바라보는 행위가 동시에 일어나는 것을 의미한다. 주체가 동시에 주체이면서 객체이기도 한 것이며, 객체(혹은 객체의 응시)를 통해서 주체(혹은 주체의 시선)가 정초되는 차원을 의미한다. 그러므로 이처럼 시선과 응시가 교차되는 차원 혹은 주체와 객체가 분리되면서 통합되는 메커니즘 다르게는 시선의 정치학은 자기 속에 타자에 대한 이해에 해당하는 일종의 타자론을 내재화한다. \n' +
                '작가는 이런 차원이며 경지를 풍경이라고 부른다. 작가에게 풍경은 말하자면 관조적인 삶이 전개되는 지평으로 보면 되겠다. 이처럼 작가에게 삶은 관조적인 삶이면서 동시에 풍경으로서 다가온다. 그러나 정작 작가의 그림에서 풍경의 실체에 해당할 만한 경우를 찾아보기는 어렵다. 작가의 그림은 적어도 재현적인 경우로서의 풍경과는 거리가 멀다. 그러나 관조적인 삶이란 주제의식을 생각하면 문제는 달라진다. 작가의 그림은 말하자면 관조적인 삶의 주제에 걸 맞는 형식 이를테면 반추상화된, 관념적인, 상징적이고 암시적인 경우로서의 풍경을 덧입고 있는 것이다. 이 풍경은 사실은 작가의 자의식이 밀어올린 풍경이며, 그런 만큼 작가에게 삶의 풍경은 동시에 자의식의 풍경이기도 하다. \n' +
                '여기서 다시, 보는 문제에 초점이 맞춰진 주제의식을 상기할 일이다. 작가에게 본다는 것은 삶을 관조하는 일이며, 동시에 자기 스스로를 관망하는 일이다. 그러므로 작가가 그려놓고 있는 삶의 풍경은 동시에 일종의 내면적 풍경이면서 자기초상의 또 다른 한 형식일 수 있다. 본다는 것(시선의 문제), 산다는 것(삶의 문제), 그리고 그린다는 것(재현의 문제)의 문제가 하나로 합치되는 경우의 그림으로 보면 되겠다. 이로써 작가의 그림은 자기반성적인 경향성을 그려놓고 있는 것이며, 그림을 통해서 자기자아를 찾아가는 과정을 그려놓고 있는 것이다. \n' +
                '\n' +
                '그렇다면 이런 관조적인 풍경이며 삶의 풍경은 어떻게 형식화되는가. 처음에 작가는 MDF합판에 아크릴 채색을 덧칠하는 식으로 그림을 그렸었다. MDF합판은 표면이 코팅돼 있어서 그 위에 덧칠되는 물감자국이며 붓질이 낱낱이 얹히고 기록되는 점이 특징이다. 제어하기가 쉽지 않은 만큼, 숙달되면 자기만의 독특한 형식을 얻을 수도 있다. 결과를 보면, 자기변신과 형식실험에 능한 작가에게 오히려 적절한 형식과 함께 긍정적인 계기로서 작용한 것 같다. \n' +
                '그 세부를 보면, 대개는 어둑한 배경 위로 비정형의 붓질 자국이 여실한 화면이 자기내면이며 무의식을 그려놓은 것 같은, 그런 반추상적인 그림이다. 작가는 이런 회화적인 과정에다 오브제를 도입해 입체적인 효과를 더한다. 흔히 석판 대용으로 쓰이는 알루미늄 판(프린트를 위해 특별히 표면을 미세요철 처리한, 일명 마판이라고도 하는)을 차용하는데, 프린트된 표면 이미지를 지워 부분적인 스크래치를 조성하기도 하고, 리토펜슬로 이미지를 그려 넣거나 한다. 단순한 오브제의 차용을 넘어, 그림 속에 또 다른 그림이 있는, 일종의 이중그림 혹은 액자그림의 형식실험이 시도되고 있는 것이다. 큰 그림과 작은 그림, 겉 그림과 속 그림 간에 내용과 형식이 부합하기도 하고 충돌하기도 하는, 그런 상호작용의 과정을 통해서 서사의 확장 내지는 심화를 꾀하는 경우로 볼 수가 있겠다. \n' +
                '그리고 여기에 또 다른 오브제로서 테라코타가 도입되는데, 그 형태가 고치 같기도 하고, 그저 되는대로 주물주물 빗어놓은 알 수 없는 형태 같기도 하다. 하지만 사실을 알고 보면 자기를 표현한 것이고 자아를 표상한 것이라 한다. 실제로도 보기에 따라서 사람 형상이 연상되기도 한다. 한편으로 그 연상의 실체는 근작에서 분명해진다. 사람 형상을 따라 오리고 그려 넣은 알루미늄 조각을 오브제로서 화면에 부착한 것이 그렇다. 그리고 또 다른 흥미로운 경우로서 짚으로 만든 오브제가 주목된다. 짚을 돌돌 말아 마치 새 둥지 같은 형태를 빗었다. 아마도 삶의 둥지며 존재의 처소를 상징할 것이다. \n' +
                '작가는 그렇게 흡사 인격의 이중성 내지 다중성을 표상한 것 같은 이중그림을, 마치 심연에서 부유하듯 내면의 질감이 감촉돼오는 감각적인 화면을, 그리고 여기에 자기를 표상하고 존재를 표상하는 오브제를 그려놓고 있었다. 그리고 그렇게 일종의 내면풍경이며 무의식의 풍경을 그려놓고 있었다. 그 풍경은 비록 작가의 개인사를 그린 것이지만, 그 풍경에 연유한 감동만큼은 작가에 한정되지는 않는다. 특히 작가의 경우에서처럼 실존적 자의식에 민감한 감각촉수를 가지고 있는 사람이라면 쉽게 공감할 수 있는 부분이 있다. 특수성을 통해 보편성을 획득하고, 개인사를 매개로 보편적으로 공감하고 공유할 수 있는 주제며 형식을 예시해주고 있다고나 할까. \n' +
                '\n' +
                '그리고 근작에서 특징적인 면으로 치자면 풍경적인 요소를 들 수가 있다. 전작에서 풍경이 잠수를 타고 있는 암시적인 형태를 취하고 있다면, 근작에서 풍경은 상대적으로 더 분명해진다. 또한 전작에서 풍경이 삶이며 존재를 대리하는 관념적인 표상의 경우라면, 근작에서 풍경은 문자 그대로 풍경으로 현상한다. 말하자면 산이 있고 하늘이 있는, 달이 있고 구름이 흐르는, 멀리 섬과 봉우리가 점경을 이루고 있는, 그리고 정처 없는 삶처럼 바람을 따라 민들레 홀씨가 풀풀 날리는, 그리고 그렇게 계절의 순리대로 꽃이 피고 지는, 그런 자연풍경이다. \n' +
                '그렇다면 작가의 관심이 존재의 자기반성적인 경향성을 파고드는 것에서 풍경으로 대리되는 자연예찬으로 옮아온 것일까. 그렇게 봐도 될까. 섣부르게 판단할 일은 아닐 것이다. 외형적으로 풍경처럼 보이지만, 그 풍경은 말 그대로 외형일 뿐, 사실은 그렇지가 않은 것 같다. 무슨 말이냐면 산인 줄 알았는데, 봉우린 줄 알았는데, 사실은 자기며 자아를 표상한 것이라 한다. 화면의 대부분을 차지하고 있는, 화면 위로 우뚝 돌출해 있는 형상이 그렇다. 전작에서의 테라코타 형상과 근작에서의 알루미늄 조각 속에 그려 넣은 사람형상의 또 다른 한 변주라는 말이다. 그렇게 작가의 그림은 사람형상으로 대리되는 존재론적 자의식을 매개로 전작과 근작이 다르면서 하나로 연이어지고 있었다. \n' +
                '자아를 표상하는 형태가 화면 위로 돌출해 있다고 했다. 그렇게 돌출된 형태가 예사롭지가 않다. 속된 표현대로라면 그저 세상구경 나온 존재를 표현한 것으로 볼 수도 있겠지만, 이보다는 좀 더 심각한 의미내용을 탑재하고 있는 것 같다. 말하자면 일종의 정신분석학적인 멘탈리티와 같은. 처음에 존재는 무의식 속에 잠수타고 있었다. 그렇게 세상은 캄캄했다. 그리고 점차 무의식이 의식의 층위로 부각되면서 존재는 비로소 그리고 점차 의식세계에 눈뜨게 됐다는 것이 정설이다. 그리고 그렇게 아직도 의식의 층위보다는 무의식의 지층이 더 깊다고 보는 사람들도 많다. 그림 속의 돌출된 형상은 그렇게 무의식의 지층에서 의식의 층위로 부각되고 부상되는 존재의 꼴을 떠올리게 하지 않는가. 한편으로 돌출된 형상은 정신분석학적으로 남근을 상징한다. 여기서 남근이란 그저 생물학적 정의라기보다는 존재론적 자의식이라는 보편적인 상징에 해당한다. 자기를 실현하려는 욕망과 리비도, 생명력의 무한표출과 코어 곧 에너지의 핵에 대한 보편상징으로 보면 되겠다. \n' +
                '존재를 찾아 심연 속을 헤매던 작가가 마침내 존재론적 원형에 도달한 것이며, 그리고 그렇게 생명력의 핵으로 표상되는 존재의 실체를 드러낸 경우로 볼 수가 있을까. 아마도 그렇게 볼 수도 있을 것이다. 그렇게 드러난 존재(잠수타고 있던 존재, 관념적으로 혹은 암시적으로 표상되던 존재와는 비교되는)가, 마침내 자기의 실체를 드러낸 존재가 향후 열어갈 또 다른 풍경의 지점이 궁금해진다.\n' +
                '\n'
        },
        {
            title:'2010 김광미 개인전 서문',
            author:'김영웅, IACO 국제미술협력기구사무국장',
            sub:'2010 개인전서문',
            menu:'김영웅',
            content:'김 광 미의 삶-바라보고...바라보기는 인간 존재가치에 대한 사유로서 자아실현을 위한 성찰의 토로물이다. 삶에 대한 관조로서의 풍경과 삶에 대한 포용으로서의 주체가 한편에 치우쳐 제자리에 머물러 있지 않고 양방향의 실존적인 시각적 입장을 넘나들며 삶의 정체성을 탐색하고 사유하는 예술가 본연의 정신적, 인간적인 고뇌와 노고가 드러나는 연작 작업이다. \n' +
                '우주나 허공 같은 여백과 삶에 대해 사유하는 테라코타 채색군상, 나뭇가지, 흙, 석판 조각 등의 오브제.. 상호 관계에서 느껴지는 우연과 필연, 유대감과 소통의 이면에는 관조와 묵상이라는 깊은 휴머니즘이 배어 있음을 발견하게 한다.\n' +
                '그녀의 안주하지 않는 실험정신과 창작에 대한 일련의 열정은 내면의 세계에서 비롯되어 우주를 돌아 삶의 변주곡으로 발현되고 타자와의 소통과 유대감을 형성하는 실존에 이른다. 그녀의 실험적 표현은 삶에 대한 회의와 부정을 딛고 자연의 순리와 긍정의 중간지대를 거치며 보다 명쾌한 형상들과 리듬을 획득함으로써 자아존재의 뚜렷한 발기와 적극적인 자아실현의 궤도를 형성해 내고 있다. 이는 몽환적 질감의 배경에 달과 구름 등의 구체화된 주변요소와 어울리며 내보여지고 드러내지는 자아의 실체를 힘 있게 융기된 산으로 상징화한 남근을 통해 암시함과 동시에 강렬하고 명료하게 표현한 자아를 중심으로 자연적인 삶의 생동감을 유희하는 것으로 나타난다, 이로써 그녀 내외의 긴요한 관조로 부터의 삶은 작품을 통해 긍정으로 해석되고 즐기는 듯한 유희로 전이하며 관조와 아울러 놀이의 유희적 삶을 함께 포용해 낸다. 때문에 작가는 오늘도 내일도 삶과 예술 속에서 자신의 자리를 굳건히 지키고 있을 수 있는 것이다. \n' +
                '더 나아가 작가 삶에 대한 사유의 결과물로의 작품은 상업자본주의속에서 예술이 담당해야할 일련의 소통방식으로써 보다 적극적인 현실 참여로 상품성과 장식성을 염두에 두고 있음을 엿볼 수 있다. 삶에 대한 내밀한 영감과 직관을 도외시하고 마네킹에 옷을 입힌 듯 형식적인 생명력으로 아쉽게 사라져가는 많은 작품들 가운데 진지한 논리와 감성, 예술혼과 지성으로 감동을 전해주는 작품의 바람직한 상업적 시도는 간과해서는 안 되는 미술계의 매우 중요한 현실적 문제다. 그래서 작가는 깊이 있지만 제대로 드러나지 않는 관조와 묵상의 숨겨진 힘이나 때론 공감을 얻기 힘든 예술지상주의 주체위주의 주관적 해석을 현대적 특성에 맞게 재구성해나가는 동시대 예술가의 책무와 예리함을 갖추고 있음을 함께 보여준다.\n' +
                '\n' +
                '작가의 삶 –바라보고...바라보기 연작은 삶의 정체성 탐색과 자아실현의 방식으로 끊임없이 소생되는 작가의 창작열정으로서 그녀의 부친 故김수열 포토아티스트의 선구자적인 시각적 실험정신과 맥을 같이한다. 두 세대에 걸쳐 삶과 예술에 대한 의미를 반추하며 나란히 동행하여 걸어가는 기재가 되어 있는 것이다. 때문에 작가는 속삭인다. 함께 갈 수 있는 길을 열어주신 아버지께 감사드리며..라고.\n' +
                '삶의 관조와 묵상, 따뜻하며 온유한 긍정적 생동감, 때론 강렬함을 포함하여 포토아티스트 고 김수열의 누드실험과 작가 김광미 삶의 주체로서 이입된 남녀의 성적 상징은 삶의 중요한 섹슈얼리즘적 관능을 내포한 휴머니티를 바탕으로 하고 있으나 순수한 조형의 상징성을 우선시 하고 있는 점이 같아 보인다. 이는 삶의 연속성에 순응하며 무구한 범위를 끊임없이 긴요하게 포용해 나가야 하는 작가의 사명에 대한 최선과 전략적 선택을 중요시함을 보여주고 있다. 이로 인해 본능적 요소의 원초적 힘을 단발로 표현하기 보다는 에너지를 담고 있는 함축적이며 조형적인 외형을 통해 무한한 변이와 가능성에 대한 은유적 접근을 제시하고 삶과 예술 속에 자아실현이라는 창작동기의 명제를 영원히 모색할 여지를 남겨두고 있는 것이다.\n' +
                '\n'
        },
    ]
}
